<template>
  <img ref="image" :src="imgObjectURL" :width="width">
</template>

<script>
import CompanyPRModel from '@/model/CompanyPR'
export default {
  props:["s3Path", 'width'],
  data: function(){
    return {
      imgObjectURL: ""
    }
  },
  created: function(){
    this.loadImage();
  },
  methods: {
    loadImage(){
      CompanyPRModel.getImageFile(this.$store.state.user.companyCode, this.s3Path).then(file => {
        const blob = new Blob([file.data], { type: 'application/image' });
        this.imgObjectURL = URL.createObjectURL(blob);
      }).catch(reason => this.$errorMessage("画像ファイルを読み込みできませんでした。", {reason}));
    }
  },
  watch: {
    's3Path':  {
      handler:function (){
        if(this.$refs.image){
          URL.revokeObjectURL( this.$refs.image.src);
        }
        if(this.s3Path){
          this.loadImage();
        }
      }
    }
  }
}
</script>

<style>

</style>
import Model from './_base';

export default class SupplierPR extends Model {
    static entity = 'pr';

    constructor() {
        super(SupplierPR.entity);
    }

    static getPR(companyCode){
        return Model.axios.get(this.url('/' + companyCode));
    }

    static save(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode), data);
    }
    
    static getImageFile(companyCode, path){
        return Model.axios.get(this.url('/' + companyCode + '/content/' + path), { responseType: 'blob' });
    }
}
<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">システム管理</router-link></li>
      <li><router-link to="/supplier/manage/pr">PR情報</router-link></li>
      <li><a>プレビュー</a></li>
    </ul>
    <div class="page container">
      <div class="page-title">
        <img class="mr-2" src="@/assets/images/pr_preview/supplier-icon.png" />
        <b>{{company.basic.fullName}}</b>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h5 class="title-item mb-3">会社案内</h5>
        </div>
        <div class="col-3 pt-2" v-if="form.companyInfo.fileManagementCode">
          <S3Image :s3Path="form.companyInfo.fileManagementCode" width="100%"/>
        </div>
        <div class="col-3 pt-2" v-if="form.companyInfo.videoUrl">
          <div class="p-2 bg-white">
            <b>動画URL</b>
          </div>
          <a :href="form.companyInfo.videoUrl">{{form.companyInfo.videoUrl}}</a>
        </div>
        <div class="col-9">
          <pre class="formatted-content">{{form.companyInfo.content}}</pre>
        </div>
      </div>
      <div class="container background-body mt-4 p-4">
        <div class="row">
          <div class="col-2">
            <div class="p-2 text-center bg-white">
              <b>所在地</b>
            </div>
          </div>
          <div class="col-4 p-2">
            〒{{company.basic.postalCode}}
            <br/>
            {{company.basic.address1 + company.basic.address2}}
          </div>
          <div class="col-2">
            <div class="p-2 text-center bg-white">
              <b>設立年月</b>
            </div>
          </div>
          <div class="col-4 p-2">{{company.summary.establishmentDateYear + '年' + company.summary.establishmentDateMonth + '月'}}</div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <div class="p-2 text-center bg-white">
              <b>従業員数</b>
            </div>
          </div>
          <div class="col-4 p-2">{{company.summary.employeesNumber}}名</div>
          <div class="col-2">
            <div class="p-2 text-center bg-white">
              <b>URL</b>
            </div>
          </div>
          <div class="col-4 p-2 break-line">{{company.summary.url}}</div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <div class="p-2 text-center bg-white">
              <b>業種</b>
            </div>
          </div>
          <div class="col-10 p-2">
            <p class="mb-1" v-for="industry in company.industries" :key="industry.code">
              {{industry.topIndustryName + ' > ' + industry.midIndustryName + ' > ' + industry.lowIndustryName}}
            </p>
          </div>
        </div>
      </div>
      <div class="row pb-5 ml-0 mr-0 border-bottom"></div>
      <div class="mt-5">
        <h5 class="title-item mb-3">商品・サービスのご紹介</h5>
      </div>
      <div class="row" v-for="(service) in form.services" v-bind:key="service.id">
        <div class="col-3 pt-2" v-if="service.fileManagementCode">
          <S3Image :s3Path="service.fileManagementCode" width="100%"/>
        </div>
        <div class="col-3 pt-2" v-if="service.videoUrl">
            <div class="p-2 bg-white">
              <b>動画URL</b>
            </div>
            <a :href="service.videoUrl">{{service.videoUrl}}</a>
        </div>
        <div class="col-9">
          <pre class="formatted-content">{{service.content}}</pre>
        </div>
      </div>
      
      <div class="row pb-5 ml-0 mr-0 border-bottom"></div>
      
      <div class="mt-5">
          <h5 class="title-item mb-3">営業からのメッセージ</h5>
        </div>
      <div class="row">
        <div class="col-1 pt-2" v-if="form.message.fileManagementCode">
          <S3Image class="rounded-circle" :s3Path="form.message.fileManagementCode" width="100%"/>
        </div>
        <div class="col-1 pt-2" v-if="form.message.videoUrl">
            <div class="p-2 bg-white">
              <b>動画URL</b>
            </div>
            <a :href="form.message.videoUrl">{{form.message.videoUrl}}</a>
        </div>
        <div class="col-7">
          <pre class="formatted-content">{{form.message.content}}</pre>
        </div>
        <div class="col-4">
          <div class="container p-2 background-body">
            <div class="row">
              <div class="col-12 text-center"><b>お問い合わせ先</b></div>

              <div class="mt-2 col-4">
                <div class="p-2 text-center small-text bg-white"><b>所在地</b></div>
              </div>
              <div class="col-8 mt-2 p-2 small-text">{{form.message.data.address}}</div>
              <div class="mt-2 col-4">
                <div class="p-2 text-center small-text bg-white">
                  <b>担当者</b>
                </div>
              </div>
              <div class="col-8 mt-2 p-2 small-text">{{form.message.data.name}}</div>
              <div class="mt-2 col-4">
                <div class="p-2 text-center small-text bg-white">
                  <b>電話</b>
                </div>
              </div>
              <div class="col-8 mt-2 p-2 small-text">{{form.message.data.phoneNumber}}</div>
              <div class="mt-2 col-4">
                <div class="p-2 text-center small-text bg-white">
                  <b>メール</b>
                </div>
              </div>
              <div class="col-8 mt-2 p-2 small-text">{{form.message.data.email}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-5 ml-0 mr-0 border-bottom"></div>
      
      <div class="mt-5">
        <h5 class="title-item mb-3">表彰情報</h5>
      </div>
      <div class="row">
        <div class="col-12">
          <pre class="formatted-content">{{form.awards.content}}</pre>
        </div>
      </div>

        
      <div class="mt-4">
        <a href="javascript:window.close();">PR情報に戻る</a>
      </div>
    </div>
  </div>
</template>

<script>
  import CompanyInfoModel from '@/model/CompanyInfo';
  import S3Image from '@/components/common/S3Image.vue';

  export default {
    components: {S3Image},
    data() {
      return {
        company: {
          basic: {},
          summary: {},
        },
        form: {
          companyInfo: {},
          services: [],
          message: { data: {} },
          awards: {}
        }
      }
    },
    methods: {
      getCompanyInfo() {
        CompanyInfoModel.getSummary(this.$store.state.user.companyCode).then((result) => {
          this.company = result.data;
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }
    },
    mounted() {
      this.getCompanyInfo();
      if (this.$store.state.prPreview) {
        this.form = this.$store.state.prPreview;
      }
    }
  };
</script>

<style>
  .break-line {
    word-break: break-word;
  }

  .title-page {
    font-weight: bold;
    font-size: 20px;
  }

  .title-item {
    font-weight: bold;
    font-size: 19px;
  }

  .formatted-content{
    font-family: auto;
  }

  .level-bg {
    background: url("~@/assets/images/pr_preview/light-primary-bg.png");
    background-size: cover;
    display: flex;
  }

  .level-item {
    display: flex;
    align-items: center;
  }

  .level-item:not(:first-child) {
    padding-left: 20px;
  }

  .level-item {
    padding-right: 20px;
  }

  .star-item {
    display: flex;
    align-items: center;
  }

  .svg-inline--fa {
    margin-right: 3px;
    font-size: 12px;
  }

  .border-r {
    border-right: 1px solid #fff;
  }

  .btn-item {
    background: -webkit-gradient(linear, left top, left bottom, from(#6d8ce8), to(#4169e1));
  }

  .icon-btn-item {
    font-size: 15px;
    margin-right: 16px;
  }

  .background-body {
    background-color: #f5f5f5;
  }

  .background-light {
    background-color: #edf1f6;
  }

  .small-text {
    font-size: small;
  }

  .border-table td,
  .border-table th {
    border: 1px solid #dbdbdb;
    border-collapse: collapse;
    background-clip: padding-box;
  }

  .border-bottom-white {
    border-bottom: 1px solid #fff;
  }

  .control {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
  }

  .textarea {
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: 0.625em;
    resize: vertical;
  }

  .modal-lg {
    max-width: 80%;
  }

  .modal-title {
    font-size: 24px;
  }

  .modal-header {
    background-color: whitesmoke;
  }

  .modal-footer {
    background-color: whitesmoke;
  }

  .vue-star-rating .vue-star-rating {
    border: 1px solid #d3d3d3;
    padding: 0px 8px 5px;
    border-radius: 5px;
  }
</style>